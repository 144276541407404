import type { GrapeAppAuthorization } from "@grape-law-firm/grape-common-notion-client";

export function useAuthorizations() {
  const { authorizations } = storeToRefs(useUserStore());
  const { navItems } = useNavigation();

  function can(required: GrapeAppAuthorization[]) {
    return required.every(requiredPermission => authorizations.value.includes(requiredPermission));
  }

  const canGoToPath = (path: string) => {
    // If path is home page, we can always go to it
    if (path === "/")
      return true;

    const navItem = navItems.find(navItem => path.startsWith(navItem.to));

    // If the path is doesn't exist in the navigation list, we can't go to it
    if (!navItem)
      return false;

    // If the nav item doesn't require any authorization, we can always go to it
    if (!navItem.requiredAuthorizations)
      return true;

    return navItem.requiredAuthorizations(authorizations.value);
  };

  return { can, canGoToPath };
}
